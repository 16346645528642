<template>
  <div class="product-item headline">
    <div class="productLineWrap">
      <div class="lineitem l-checkbox first">
        <span> </span>
      </div>
      <div class="lineitem l-state" style="width: 24px;">
      </div>
      <div class="lineitem l-titlesmall">
      </div>
      <div class="lineitem l-type l-icon"></div>
      <div class="lineitem l-task l-icon l-receiver"></div>
      <div class="lineitem  l-job l-datetime">
        <span>Geplant</span>
      </div>
      <div class="lineitem l-finished l-datetime">
        <span>Ausführung</span>
      </div>
      <div class="lineitem l-date l-datetime">
        <span>Erstellt</span>
      </div>
      <div class="lineitem l-avatar"><div style="width: 32px; height: 1px;"></div>
      </div>
      <div class="lineitem l-mails">
        <span>Betreff</span>
      </div>
      <div class="lineitem l-action last"></div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections: {
        id: '',
        checkbox: '',
        state: '',
        type: '',
        task: '',
        job: '',
        finished: '',
        date: '',
        avatar: '',
      }
    }
  },
  props: {
    queue: Object,
  },
  computed: {
    ...mapGetters(["queueActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }
      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }
      // Emit the clicked type and direction
      this.$emit('sort', {type: clickedType, direction: this.sortDirections[clickedType]});
    }
  },
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
