<template>
  <div class="card">
    <template v-if="!activeRequest">
      <div class="card-header">
        <h6 style="display: inline;">Bewerbungen</h6>
        <p style="padding-left:24px; display: inline;" v-if="requests && requests.length">{{ requests.length }} gesamt
          davon
          {{ filteredRequests.length }} gefiltert</p>
        <div style="display: inline-block; width: 200px;margin-left: 24px; margin-bottom: 0;" class="input-group-sm">
          <input style="margin-bottom: 0;" type="text" class="form-control" @keyup="filterRequests()"
                 v-model="search.phrase"
                 placeholder="Nachname suchen...">
        </div>

        <div class="btn-group float-end">
          <button class="btn btn-outline-primary btn-sm" @click="getExport()" :tooltip="'CSV exportieren'"
                  position="top">
            <i class="fa-duotone fa-file-export"></i>
          </button>
          <button class="btn btn-outline-primary btn-sm" @click="getExportPDF(false)" position="top" tooltip="PDF
          Exportieren"><i class="fa-duotone fa-file-pdf"></i>
          </button>
          <button class="btn btn-outline-secondary btn-sm" @click="getExportPDF(true)" position="top" tooltip="Erweiterte PDF
          Exportieren"><i class="fa-duotone fa-file-pdf"></i>
          </button>

          <button class="btn btn-outline-danger btn-sm"
                  @click="showMessage = !showMessage" tooltip="Massenmail" position="left"><i
            class="fa-duotone fa-envelopes-bulk"></i>
          </button>
        </div>
        <div class="input-group float-end me-2" style=" width: 270px" v-if="selectedRequests.length > 0 && activeLicense ">
          <select v-model="moveType" class="form-select small" >
            <option value="">Zuweisung ändern</option>
            <template v-for="(value, key) in renderState">
            <option v-if="(activeLicense.choosePrepare && !activeLicense.choosePrepare.chooseable && key !== 'prio_1' && key !== 'prio_2' && key !== 'prio_3' && key !== 'prio_4') || activeLicense.choosePrepare && activeLicense.choosePrepare.chooseable"  :value="key"  >{{ value.text }}</option></template>
          </select>
          <button class="btn btn-outline-primary btn-sm" tooltip="Änderung auf gewählte Zuweisung" position="top" v-if="selectedRequests.length > 0 && moveType !== ''"
                  @click="moveList(moveType)"><i class="fa-duotone fa-check"></i>
          </button>
        </div>
      </div>
    </template>
    <div class="card-body" v-if="!activeRequest">
      <div class="row badgeCountRow" v-if="requests && requests.length > 0">
        <div class="col-12">
          <button class="btn btn-outline-secondary me-1 smallerBtn" :class="{'btn-success': (search.state === '')}"
                  @click="search.state = '';filterRequests()"> {{ $t("plugins.dfb.status.anyStatus") }}
            <span class="badgeCount">{{countBadge('', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'active')}"
                  @click="search.state = 'active';filterRequests()">{{ $t("plugins.dfb.status.active") }}
            <span class="badgeCount">{{countBadge('active', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'progress')}"
                  @click="search.state = 'progress';filterRequests()">{{ $t("plugins.dfb.status.progress") }}
            <span class="badgeCount">{{countBadge('progress', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'checking')}"
                  @click="search.state = 'checking';filterRequests()">{{ $t("plugins.dfb.status.checking") }}
            <span class="badgeCount">{{countBadge('checking', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'discus')}"
                  @click="search.state = 'discus';filterRequests()">{{ $t("plugins.dfb.status.discus") }}
            <span class="badgeCount">{{countBadge('discus', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'accepted')}"
                  @click="search.state = 'accepted';filterRequests()">{{ $t("plugins.dfb.status.accepted") }}
            <span class="badgeCount">{{countBadge('accepted', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined')}"
                  @click="search.state = 'declined';filterRequests()">{{ $t("plugins.dfb.status.declined") }}
            <span class="badgeCount">{{countBadge('declined', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_02')}"
                  @click="search.state = 'declined_02';filterRequests()">{{ $t("plugins.dfb.status.declined_02") }}
            <span class="badgeCount">{{countBadge('declined_02', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_03')}"
                  @click="search.state = 'declined_03';filterRequests()">{{ $t("plugins.dfb.status.declined_03") }}
            <span class="badgeCount">{{countBadge('declined_03', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_04')}"
                  @click="search.state = 'declined_04';filterRequests()">{{ $t("plugins.dfb.status.declined_04") }}
            <span class="badgeCount">{{countBadge('declined_04', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_05')}"
                  @click="search.state = 'declined_05';filterRequests()">{{ $t("plugins.dfb.status.declined_05") }}
            <span class="badgeCount">{{countBadge('declined_05', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_user')}"
                  @click="search.state = 'declined_user';filterRequests()">{{ $t("plugins.dfb.status.declined_user") }}
            <span class="badgeCount">{{countBadge('declined_user', requests)}}</span>
          </button>
          <template v-if="activeLicense && activeLicense.choosePrepare && activeLicense.choosePrepare.chooseable">
            <button class="btn btn-outline-secondary me-1 smallerBtn"
                    v-for="(choose, key) in activeLicense.choosePrepare.chooseTask"
                    :class="{'btn-success': (search.state === 'prio_'+(parseInt(key)+1))}"
                    :id=" 'prio_'+(parseInt(key)+1)"
                    @click="search.state = 'prio_'+(parseInt(key)+1);filterRequests()">{{ choose.title }}
              <span class="badgeCount">{{countBadge('prio_'+(parseInt(key)+1), requests)}}</span>
            </button>
          </template>
        </div>
      </div>
      <hr>
      <div class="row badgeCountRow">
        <div class="col-12">
          <button class="btn btn-outline-secondary me-1 smallerBtn" :class="{'btn-success': (search.type === '')}"
                  @click="search.type = '';activeLicense=null;getRequests()">Jede Lizenz
          </button>
          <select name="" id="" class="small" style="float:right;" v-model="activeLicense" @change="getRequests()">
            <option value="">- Archiv -</option>
            <template :key="ty.id" v-for="ty in requestTypes">
              <option v-if="ty.state === 'archived' || ty.state === 'prepare'" :value="ty">
                {{ ty.name }} {{ $filters.formatDate(ty.published_to) }}
              </option>
            </template>
          </select>
          <template :key="ty.id" v-for="ty in requestTypes">
            <button v-if="ty.state !== 'archived' && ty.state !== 'prepare'"
                    class="btn btn-outline-secondary me-1 smallerBtn"
                    :class="{'btn-success': (search.type === ty.id)}"
                    @click="search.type = ty.id; activeLicense = ty; getRequests()">
              {{ ty.name }} {{ $filters.formatDate(ty.published_to) }}
            </button>
          </template>
        </div>
      </div>
      <hr>
      <div class="row badgeCountRow" v-if="requests && requests.length > 0">
        <div class="col-4">
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.international === false && search.specialOrder === false && search.aufsteiger === false && search.doppel === false)}"
                  @click="search.international = false;search.specialOrder = false;search.aufsteiger = false;search.doppel = false;filterRequests()">
            Alle
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.international === true)}"
                  @click="search.international = true;filterRequests()">Internationale
            <span class="badgeCount">{{countBadgeSpecial('international', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.aufsteiger === true)}"
                  @click="search.aufsteiger = true;filterRequests()">Aufsteiger
            <span class="badgeCount">{{countBadgeSpecial('aufsteiger', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.doppel === true)}"
                  @click="search.doppel = true;filterRequests()">Doppelbewerber
            <span class="badgeCount">{{countBadgeSpecial('doppel', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.specialOrder === true)}"
                  @click="search.specialOrder = true;filterRequests()">Sonderanträge
            <span class="badgeCount">{{countBadgeSpecial('specialOrder', requests)}}</span>
          </button>
        </div>
        <div class="col-4">
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.gender === false)}"
                  @click="search.gender = false;filterRequests()">Alle
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.gender === 'Weiblich')}"
                  @click="search.gender = 'Weiblich';filterRequests()">Weiblich
            <span class="badgeCount">{{countBadgeGender('Weiblich', requests)}}</span>
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.gender === 'Männlich')}"
                  @click="search.gender = 'Männlich';filterRequests()">Männlich
            <span class="badgeCount">{{countBadgeGender('Männlich', requests)}}</span>
          </button>
        </div>
        <div class="col-4">
          <!-- Sortierung nach Name, Punkte, Bewerbungszeitraum -->
          <select style="margin-right: 12px;" class="small" @change="filterRequests();" v-model="search.sort">
            <option value="c_lastname">Name</option>
            <option value="finalPoints">Punkte</option>
          </select>
          <select class="small" @change="filterRequests();" v-model="search.order">
            <option value="asc">Aufsteigend</option>
            <option value="desc">Absteigend</option>
          </select>
        </div>
      </div>
      <div class="row" v-else><div class="col-12"><p>Bitte wähle eine Bewerbungsphase.</p></div></div>
      <div class="row">
        <div class="col-12">
          <Loading :loading="loading" v-if="loading"></Loading>
          <div class="singleRequest" v-else v-for="(request,inde) in filteredRequests" :key="request.id">
            <div class="pluginWrap" v-if="request.name">
              <div class="plugin-item">
                <div class="lineitem l-checkbox">
                  <div class="checkbox">
                    <input type="checkbox" :id="'id_'+request.id" :value="request.id" v-model="selectedRequests"/>
                    <label :for="'id_'+request.id"></label>
                  </div>
                </div>
                <div class="lineitem l-number" :ref="'request'+request.id">
                  <span class="indexer w-100"><button @click="activeRequest = request.id"
                                                      class="btn btn-outline-primary btn-xs w-100">
                  {{ inde + 1 }} </button></span>
                </div>
                <div class="lineitem l-title"><strong @click="activeRequest = request.id">{{ request.c_firstname }}
                  {{ request.c_lastname }}</strong>
                  <!--                  <p v-if="request.custom_fields" class="text-sm text-secondary mb-4">{{request.custom_fields.mannschaft}}<br>{{request.custom_fields.liga}}<br>{{request.custom_fields.altersbereich}}</p>-->
                </div>
                <div class="lineitem l-prio">
                  <div style="display: none;">{{ request }}</div>
                  <template v-if="request.published_from && request.published_to">
                    <span class="roundBadge" :class="renderGradeClass(request.name)"
                          :tooltip="$filters.formatDate(request.published_from)+' - '+$filters.formatDate(request.published_to)"
                          :style="'background-color:'+request.color+';'"
                          position="right">{{ renderGradeName(request.name) }}</span></template>
                  <template v-else><span class="roundBadge" v-if="request.name" :class="renderGradeClass(request.name)"
                                         :style="'background-color:'+request.color+';'"
                                         :tooltip="request.name">{{ request.name.substring(0, 2) }}</span>
                  </template>
                </div>
                <div class="lineitem l-type"><span v-html="renderProgress(request.state)"></span>
                </div>
                <div class="lineitem l-infos l-smallbadge">
                  <span class="badge badge-secondary w-100" v-if="request.gender">
                    <template v-if="request.gender === 'Männlich'">
                      <i class="fa-duotone fa-mars"></i>
                    </template>
                    <template v-else>
                      <i class="fa-duotone fa-venus"></i>
                    </template></span>
                  <span class="badge badge-outline w-100" v-else></span>
                </div>
                <div class="lineitem l-points ">
                  <p class="text-sm text-secondary mb-0" tooltip="Punkte">{{ request.finalPoints }}</p>
                </div>
                <div class="lineitem l-infos">
                  <span v-if="request.international" class="badge bg-outline-primary badge-secondary w-100 ">International</span>
                  <span v-else class="badge badge-outline badge-secondary w-100 ">International</span>
                </div>
                <div class="line-item l-infos me-1">
                  <span v-if="request.custom_fields && request.custom_fields.aufstieg"
                        class="badge badge-secondary w-100">Aufstieg</span>
                  <span v-else class="badge badge-outline w-100">Aufstieg</span>
                </div>
                <div class="line-item l-infos me-1">
                  <span v-if="request.doppel" class="badge badge-secondary w-100">Doppelbewerber</span>
                  <span v-else class="badge badge-outline w-100">Doppelbewerber</span>
                </div>
                <div class="line-item l-infos me-1">
                  <span v-if="request.specialOrder" class="badge badge-secondary w-100">Sonderantrag</span>
                  <span v-else class="badge badge-outline w-100">Sonderantrag</span>
                </div>
                <div class="line-item l-infos me-1">
                  <template v-if="request.history.activeCount === 1">
                    <span class="badge badge-outline w-100">Bewerbung 1</span>
                  </template>
                  <template v-else-if="request.history.activeCount > 1">
                    <span class="badge badge-secondary w-100"
                          @click="showRequest(request)">Bewerbung {{ request.history.activeCount }}</span>
                  </template>
                </div>
                <div class="line-item l-infos l-smallbadge ml-auto">
                  <template v-if="request.notices && request.notices.length === 0">
                    <span class="badge badge-outline" @click="showNotice(request.notices)"><i
                      class="fa-clipboard-list-check fa-duotone" title="Notiz verfassen"></i> 0</span>
                  </template>
                  <template v-else-if="request.notices && request.notices.length > 0">
                    <span class="badge badge-secondary" @click="showNotice(request.notices)"><i
                      class="fa-clipboard-list-check fa-duotone"
                      title="Notizen"></i> {{ request.notices.length }}</span>
                  </template>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <button @click="lastRequest = activeRequest; activeRequest = null; getRequests()"
              class="btn btn-outline-danger btn-sm"
              style="position: absolute; top: 16px; right: 24px;"><i class="fa-duotone fa-close"></i> Zurück
      </button>
      <ActiveRequest :activeRequest="activeRequest"/>
    </div>
  </div>
  <Modal v-if="showMessage">
    <div class="boxGrey">
      <h6>Notiz, verfassen</h6>
      <select style="width: 100%; height: 30px; margin-bottom: 24px;"
              v-model="activeDraft"
      >
        <option :value="''">- Aus Vorlage wählen -</option>
        <optgroup :label="tit" v-for="(dr,tit) in activeDrafts">
          <option :value="draft" v-for="draft in dr" :key="draft.id">{{ draft.title }}</option>
        </optgroup>
      </select>
      <template v-if="activeDraft.attachmentTitle">
        <p class="alert alert-info">{{ activeDraft.attachmentTitle }} wird als Anhang mit geschickt!</p>
      </template>
      <input class="w-100 mb-2" type="text" v-model="headline">
      <Editor2 v-model="comment"/>
      <input class="w-100 mb-2 mt-2" type="text" placeholder="BCC kommasepariert" v-model="bcc">
      <input type="file" style="width: 100%; margin-top: 24px;margin-bottom: 24px;" ref="file" @change="readFile()">
      <div class="mb-4">
        <p>Diese Nachricht wird an {{ filteredRequests.length }} Kunden gesendet!</p>
        <p>{{attachments.length}} Anhänge</p>
      </div>
      <button class="mt-4 me-3 btn btn-primary" v-if="!sendingMails" @click="sendBatchMail();">SENDEN</button>
      <span v-else>Bitte warten!</span>
      <button class="mt-4 me-3 btn btn-danger" @click="showMessage = false">Abbrechen</button>
    </div>
  </Modal>
  <Modal v-if="modalRequestHistory">
    <div class="boxGrey">
      <h6>Bewerbungshistorie</h6>
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Lizenz</th>
          <th>Zeitpunkt</th>
          <th>Punkte</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="history in activeModalRequest.history.history" :key="history.id">
          <td>{{ history.name }}</td>
          <td>{{ $filters.formatDate(history.published_from) }}</td>
          <td>{{ history.finalPoints }}</td>
          <td>
            <button type="button" class="btn btn-outline-primary"
                    @click="modalRequestHistory = false;activeRequest=history.id;"><i class="fa-duotone fa-edit"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <button class="mt-4 me-3 btn btn-danger" @click="modalRequestHistory = false">Schließen</button>
    </div>
  </Modal>
  <Modal v-if="modalRequestNotices">
    <div class="boxGrey">
      <h6>Notizen</h6>
      <template v-if="activeModalNotices && activeModalNotices.length > 0">
        <div class="noticesContainer">
          <template v-for="n in activeModalNotices">
            <div class="noticeItem">
              <div class="noticeHeader mb-2">
                <button type="button" class="btn btn-outline-danger btn-sm float-end" @click="deleteNotice(n)"><i
                  class="fa-duotone fa-trash"></i></button>
                <b>{{ n.notice_type }}</b>
              </div>
              <div class="noticeMessage" v-html="n.notice"></div>
              <hr>
            </div>
          </template>
        </div>
      </template>
      <button class="mt-4 me-3 btn btn-danger" @click="modalRequestNotices = false">Schließen</button>
    </div>
  </Modal>
</template>
<script>
import ActiveRequest from './ActiveRequest'
import axios from "axios";
import {ref} from "@vue/runtime-dom";
import Editor2 from "@/components/Editor2.vue";
import Loading from "@/components/Loading"
import Pagination from "@/filter/components/pagination.vue";

export default {
  name: "DFBRequest",
  components: {
    Pagination,
    Editor2,
    ActiveRequest,
    Loading
  },
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  methods: {
    sendBatchMail() {
      if (confirm("Wirklich an die aktuelle Auswahl senden?") === true) {
        this.sendingMails = true;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.put(this.$store.state.apiUrl + "plugins/dfb/sendBatchMail/", {
          data: this.filteredRequests,
          comment: this.comment,
          headline: this.headline,
          attachment: this.activeFile,
          attachments: this.attachments,
          filename: this.activeFileName,
          bcc: this.bcc,
        }, {headers}).then(() => {
          this.showMessage = false;
          this.comment = '';
          this.activeFile = '';
          this.headline = '';
          this.sendingMails = false;
        });
      } else {
        return false;
      }
    },
    showRequest(request) {
      this.activeModalRequest = request;
      this.modalRequestHistory = true;
    },
    showNotice(request) {
      this.activeModalNotices = request;
      this.modalRequestNotices = true;
    },
    deleteNotice(notice) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "plugins/dfb/requestNotice/" + notice.id, {headers}).then(() => {
        this.getRequests();
        this.modalRequestNotices = false;
      });
    },
    scrollTo(selector) {
      if (this.$refs[selector] && this.$refs[selector][0]) {
        this.$refs[selector][0].scrollIntoView({behavior: "smooth"});
      }
    },
    // filter requests by request.c_lastname
    filterRequests: function () {

      this.filteredRequests = this.requests;
      if (this.search.phrase !== '') {
        this.filteredRequests = this.filteredRequests.filter(request => request.c_lastname.toLowerCase().includes(this.search.phrase.toLowerCase()))
      }
      if (this.search.type !== '') {
        this.filteredRequests = this.filteredRequests.filter(request => request.request_id === this.search.type)
      }
      if (this.search.gender !== false) {
        this.filteredRequests = this.filteredRequests.filter(request => request.gender === this.search.gender)
      }
      if (this.search.state !== '') {
        this.filteredRequests = this.filteredRequests.filter(request => request.state === this.search.state)
      }
      if (this.search.international) {
        this.filteredRequests = this.filteredRequests.filter(request => request.international === true)
      }
      if (this.search.aufsteiger) {
        this.filteredRequests = this.filteredRequests.filter(request => request.custom_fields && request.custom_fields.aufstieg === true)
      }
      if (this.search.doppel) {
        this.filteredRequests = this.filteredRequests.filter(request => request.doppel === true)
      }
      if (this.search.specialOrder) {
        this.filteredRequests = this.filteredRequests.filter(request => (request.specialOrder !== null && request.specialOrder !== undefined && request.specialOrder !== ''))
      }
      if (this.lastRequest) {
        this.scrollTo('request' + this.lastRequest);
        this.lastRequest = null;
      }
      // sort and order
      if (this.search.sort === 'finalPoints') {
        this.filteredRequests.sort(this.sortRequestsFloat);
      } else {
        this.filteredRequests.sort(this.sortRequests);
      }
      this.selectedRequests = [];
    },
    sortRequestsFloat: function (a, b) {
      if (parseFloat(a[this.search.sort]) < parseFloat(b[this.search.sort])) {
        if (this.search.order === 'asc') {
          return -1;
        } else {
          return 1;
        }
      }
      if (parseFloat(a[this.search.sort]) > parseFloat(b[this.search.sort])) {
        if (this.search.order === 'asc') {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    },
    sortRequests: function (a, b) {
      if (a[this.search.sort] < b[this.search.sort]) {
        if (this.search.order === 'asc') {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[this.search.sort] > b[this.search.sort]) {
        if (this.search.order === 'asc') {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    },
    getRequestTypes() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestTypes?reduce=true", {headers}).then((r) => {
        this.requestTypes = r.data.result.data;
        this.loading = false;
      });
    },
    getRequests() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let filter = ''
      this.loading = true;
      if (this.activeLicense) {
        filter = '?requestTypeId=' + this.activeLicense.id
      } else {
        filter = '?state=productive'
      }
      if(this.search.type === null){
        this.loading = false;
      } else {
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requests" + filter, {headers}).then((r) => {
        this.requests = r.data.result.data;
        this.filterRequests();
        this.loading = false;
      });
      }
    },
    renderGradeName(name) {
      if (name === 'Torwart A Lizenz') {
        return 'TA';
      }
      if (name === 'Torwart B Lizenz') {
        return 'TB';
      }
      if (name === 'Torwart Leistungskurs') {
        return 'TL';
      }
      return name.substring(0, 2)
    },
    renderGradeClass(name) {
      if (!name) {
        return '';
      }
      const modifiedName = name
        .replace(/\+/g, "plus") // Replace + with "plus"
        .replace(/[^\w\s]/gi, "") // Remove special characters
        .replace(/\s/g, ""); // Remove spaces
      if (!modifiedName) {
        return '';
      }
      return modifiedName.substring(0, 4);
    },
    renderProgress(progress) {
      let returnProgress = '';
      returnProgress = this.renderState[progress];
      if (returnProgress === undefined) returnProgress = this.renderState['default'];
      let returnHtml = '<span tooltip="' + returnProgress.text + '" position="right" data-oro="' + progress + '"><i class="' + returnProgress.icon + '" style="color:' + returnProgress.color + '"></i></span>';
      return returnHtml;
    },
    moveList($state) {
      // get Export from API
      // javascript confirm
      if(this.selectedRequests.length === 0){
        alert("Bitte wählen Sie mindestens eine Bewerbung aus!");
        return false;
      }
      if (confirm("Sie verschieben damit die aktuelle Auswahl!") === true) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.put(this.$store.state.apiUrl + "plugins/dfb/moveRequests", {
          data: this.selectedRequests,
          state: $state
        }, {headers}).then(() => {
          this.getRequests();
        });
      } else {
        return false;
      }
    },
    getExport() {
      // get Export from API
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/exportRequests", {data: this.filteredRequests}, {headers}).then((r) => {
        let rows = r.data.result.data;
        let csvString = rows.map(e => e.join(";")).join("\n");
        // convert array in utf8
        var universalBOM = "\uFEFF";
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
        a.setAttribute('download', 'export.csv');
        window.document.body.appendChild(a);
        a.click();
      });
    }, getExportPDF(extended) {
      if (extended === undefined) {
        extended = false;
      }
      if (this.exportRunning) alert("Download im Gang!");
      this.exportRunning = true;
      // get Export from API
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/exportRequestsPDF", {
        data: this.filteredRequests,
        extended: extended
      }, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/zip;base64,' + r.data.result.data;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'export_bewerbungen.zip');
        window.document.body.appendChild(a);
        a.click();
        this.exportRunning = false;
      });
    },
    getDrafts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?limit=0", {headers}).then((r) => {
        this.drafts = r.data.result.data;
        // sort drafts by group_id in activeDrafts
        this.drafts.forEach((draft) => {
          if (this.activeDrafts[draft.categoryTitle] === undefined) {
            this.activeDrafts[draft.categoryTitle] = [];
          }
          this.activeDrafts[draft.categoryTitle].push(draft);
        });
        this.loading = false;
      });
    },
    readFile() {
      let VueInner = this;
      let base64String = null;
      for(let i = 0; i < this.$refs.file.files.length; i++) {
        base64String = null;
        this.file = this.$refs.file.files[i];
        this.$log.debug("reading");
        this.$log.debug(this.$refs);

        this.activeFileName = this.file.name;
        if (this.file) {
          var reader = new FileReader();
          reader.onloadend = () => {
            // Use a regex to remove data url part
            base64String = reader.result
              .replace('data:', '')
              .replace(/^.+,/, '');
            VueInner.attachments.push({name:VueInner.activeFileName, data: base64String});
          };
          reader.readAsDataURL(this.file);
        }
      }

      console.log(VueInner.attachments);
    },
    filterSelect() {
      if (this.filterSelected === '') {
        this.search.type = '';
        this.filterRequests();
      } else {
        this.search.type = this.filterSelected.id;
        this.activeLicense = this.filterSelected;
        this.filterRequests();
      }
    },
    countBadge(state, requests) {
      if (state === '') {
        return requests.length;
      }
      return requests.filter(request => request.state === state).length;
    },
    countBadgeGender(state, requests){
      if (state === '') {
        return requests.length;
      }
      return requests.filter(request => request.gender === state).length;
    },
    countBadgeSpecial(type, requests){
      if (type === '') {
        return requests.length;
      }
      return requests.filter(request => request[type] === true).length;
    }
  },
  data() {
    return {
      lastRequest: null,
      filterSelected: '',
      exportRunning: false,
      activeLicense: '',
      loading: true,
      loadingRequests: true,
      activeDrafts: {},
      search: {
        order: 'desc',
        sort: 'finalPoints',
        phrase: '',
        type: null,
        gender: false,
        state: '',
        international: false,
        aufsteiger: false,
        doppel: false,
      },
      selectedRequests: [],
      sendingMails: false,
      showMessage: false,
      moveType: '',
      requestTypes: [],
      requests: [],
      filteredRequests: [],
      activeRequest: null,
      requestDetails: {},
      drafts: [],
      activeDraft: '',
      comment: '',
      headline: "Es gibt Neuigkeiten zu Ihrer Bewerbung.",
      activeFile: null,
      file: null,
      activeFileName: null,
      renderState: {
        "active": {icon: "fa-duotone fa-edit", color: "#0088CC", text: "Vom Bewerber auszufüllen"},
        "progress": {icon: "fa-duotone fa-clipboard-list-check", color: "#ecaa12", text: "Vom DFB zu bearbeiten"},
        "checking": {icon: "fa-duotone fa-gear", color: "#ecaa12", text: "DFB in Bearbeitung"},
        "discus": {icon: "fa-duotone fa-gear", color: "#ecaa12", text: "DFB in Klärung"},
        "accepted": {icon: "fa-duotone fa-check", color: "#0088CC", text: "DFB akzeptiert"},
        "deleted": {icon: "fa-duotone fa-times", color: "#0088CC", text: "Vom Bewerber zurückgezogen"},
        "prio_1": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 1"},
        "prio_2": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 2"},
        "prio_3": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 3"},
        "prio_4": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 4"},
        "closed": {icon: "fa-duotone fa-times", color: "#0088CC", text: "Abgelehnt"},
        "declined": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined_01": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined_02": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Voraussetzungen nicht erfüllt"},
        "declined_03": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Korrigierte Punkte"},
        "declined_04": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Ungeprüft zurückgewiesen"},
        "declined_05": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Zu wenig Punkte"},
        "declined_user": {icon: "fa-duotone fa-circle-xmark", color: "gray", text: "Teilnehmerabsage"},
        "default": {icon: "fa-duotone fa-circle-xmark", color: "gray", text: "Fallback"},
      },
      bcc: null,
      attachments: [],
      modalRequestHistory: false,
      activeModalRequest: {},
      modalRequestNotices: false,
      activeModalNotices: {}
    };
  },
  created() {
    this.getRequests();
    this.getRequestTypes();
    this.getDrafts();
  },
  watch: {
    activeRequest() {
      window.removeEventListener("refreshTasksDFB", this.getRequestDetails);
    },
    activeDraft() {
      this.attachments = [];
      if (this.activeDraft) {
        this.comment = this.activeDraft.draft;
        this.headline = this.activeDraft.title;
        if (this.activeDraft.attachment_id) {
          this.attachments.push(this.activeDraft.attachment_id);
        }
      }
    },
    showMessage() {
    }
  }
};
</script>
<style lang="scss">
.smallerBtn {
  padding: 2px 5px !important;
  font-size: 13px !important;
  font-weight: normal !important;
  border-radius: 3px !important;
  margin-bottom: 4px !important;
}

.l-infos {
  span.badge {
    line-height: 15px;
    font-size: 12px !important;
    @media (min-width: 1600px) {
      min-width: 115px;
    }
    &.badge-secondary {
      background: #969696;
      border: 1px solid #969696;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.badge-outline {
      border: 1px solid #969696;
      color: #969696;
      background: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.l-smallbadge {
    span.badge {
      line-height: 15px;
      @media (min-width: 1600px) {
        min-width: 50px;
      }
    }
  }
}
.badgeCountRow {
  span.badgeCount {
    background: #fff;
  }

  button.btn.btn-outline-secondary.me-1.smallerBtn {
    padding: 2px 4px !important;
    border: 0;
    background:#eee;
    &:hover{
      background:#ddd;
      color: #333;
    }
  }

  button.btn.btn-outline-secondary.me-1.smallerBtn.btn-success {
    border: 0;
    background:#249b3a;
    &:hover{
      color: #fff;
    }
  }

  button.btn.btn-outline-secondary.me-1.smallerBtn .badgeCount {

    display: inline-block;
    width: 30px;
    text-align:center;
    background: #ffffff;
    color: #000;
    font-size: 10px;
    margin-left: 12px;
    padding: 3px;
    margin-right: -2px;
  }
}
.product-item {
  cursor: pointer;
}

.plugins.dfb .card-body hr {
  margin-top: 8px;
  margin-bottom: 12px;
}

.pluginWrap span.roundBadge {
  background: #969696;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.appView.plugins.dfb .singleRequest {
  .checkbox label {
    &:before {
      top: 2px;
      width: 12px;
      height: 12px;
    }
    &:after{
      top: 0px;
      width: 6px;
      height: 6px;
      left: 1px;
      font-size: 12px;
    }
  }
}
</style>
